import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import {useAnalytics} from 'react-shisell';
import {CoreText, CoreButton, CoreToast, CoreLink} from '@anywhere-expert/base-ui';
import {LightThemeProvider, palette} from '@anywhere-expert/colors';
import {LoginLogoIcon} from '@anywhere-expert/icons';
import loginBackground from './loginBackgrounds';

const Container = styled.div`
    background-color: ${palette.neutral.white};
    display: flex;
    flex: 1;
    flex-direction: row;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: Apercu Pro, sans-serif;
    font-weight: 300;
`;

const RightContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    overflow: hidden;
    justify-content: center;

    span::selection,
    b::selection {
        background-color: ${loginBackground.color};
    }
`;

const BackgroundImage = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
`;

const SloganContainer = styled.span`
    position: absolute;
    top: 10%;
    left: 9%;
    max-width: 600px;
    font-size: 4vw;
    letter-spacing: -0.03em;
    line-height: 103%;
    z-index: 4;

    @media only screen and (max-width: 1250px) {
        max-width: 425px;
    }

    @media only screen and (min-width: 1500px) {
        font-size: 60px;
    }
`;

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 410px;
    padding: ${({theme}) => theme.spacing(0, 7)};
    padding-top: 10vh;
    padding-bottom: 5vh;
    min-height: 470px;
    width: 25%;

    @media only screen and (max-width: 905px) {
        width: 100%;
    }
`;

const SignInButton = styled(CoreButton)`
    margin-top: 24px;
    letter-spacing: 1px;
    padding: 0px 64px;
`;

const LogoContainer = styled.div`
    text-align: end;
    user-select: none;
`;

const WelcomeText = styled(CoreText)`
    text-align: center;
`;

const ExpertNameContainer = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    bottom: 30px;
    left: calc(55% + 170px);
    z-index: 4;
    overflow: hidden;
    white-space: nowrap;
`;

const ExpertNameText = styled.span`
    font-size: 20px;
    font-weight: 700;
`;

const ExpertStartDateText = styled.span`
    font-size: 16px;
`;

const LoginContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: -5vh;
`;

const StyledLink = styled(CoreLink)`
    color: ${({theme}) => theme.colors.neutral.grey.deep};

    &:hover {
        color: ${({theme}) => theme.colors.neutral.grey.deepest};
    }
`;

const ErrorToast = styled(CoreToast)`
    max-width: unset;
    top: 0;
    border-radius: 0;
    justify-content: center;
    background-color: ${({theme}) => theme.colors.semantic.red.normal};
    width: 100%;
`;

type SelectLoginProps = {
    ssoLoginRedirect: () => Promise<void>;
    shouldAutoLogin?: boolean;
    error?: string;
};

const SelectLogin = ({ssoLoginRedirect, error, shouldAutoLogin = false}: SelectLoginProps) => {
    const analytics = useAnalytics();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        analytics.dispatcher.withExtra('ShouldAutoLogin', shouldAutoLogin).dispatch('View');

        if (shouldAutoLogin) {
            ssoLoginRedirect();
        }

        window.requestAnimationFrame(() => {
            const now = Date.now();
            const start = sessionStorage.getItem('FirstPaintStart');
            if (start) {
                const firstPaintLoadTime = now - parseInt(start);
                sessionStorage.setItem('FirstPaintDuration', firstPaintLoadTime.toString());
                sessionStorage.removeItem('FirstPaintStart');
            }
        });
    }, []);
    const onClickSSO = useCallback(async () => {
        analytics.dispatcher
            .withExtras({
                ActionId: 'SignInButton',
                LogInType: 'AsurionLogIn',
            })
            .dispatch('Click');

        setIsLoading(true);
        await ssoLoginRedirect();
    }, [ssoLoginRedirect]);

    if (shouldAutoLogin) {
        return <>Redirecting to Asurion SSO...</>;
    }

    //In Login page we override theme to be always light mode
    return (
        <LightThemeProvider>
            <ErrorToast showClose={false} text={error!} show={!!error} />
            <Container>
                <LeftContainer>
                    <LogoContainer>
                        <LoginLogoIcon width="100%" />
                    </LogoContainer>
                    <LoginContent>
                        <WelcomeText size="xl">
                            {getGreetingTime()} 👋
                            <br />
                            Sign in with your Asurion account
                        </WelcomeText>
                        <div>
                            <SignInButton
                                size="l"
                                isInProgress={isLoading}
                                text="Sign in"
                                variant="contained"
                                onClick={onClickSSO}
                                colorOverride={palette.brand.purple}
                            />
                        </div>
                    </LoginContent>
                    <div>
                        <CoreText textType="tertiary" size="s">
                            By signing up you agree to our&nbsp;
                            <StyledLink
                                linkType="external"
                                size="s"
                                href="https://anywhereexpert-static.mysoluto.com/media/privacy-policy.pdf"
                            >
                                Privacy Policy
                            </StyledLink>
                             &nbsp;and&nbsp;
                            <StyledLink
                                linkType="external"
                                size="s"
                                href="https://anywhereexpert-static.mysoluto.com/media/terms-of-service.pdf"
                            >
                                Terms of Service
                            </StyledLink>
                            .
                        </CoreText>
                    </div>
                </LeftContainer>
                <RightContainer>
                    <SloganContainer>
                        Are your techy senses <b>tingling?</b> Someone must need your help
                    </SloganContainer>
                    <ExpertNameContainer>
                        <ExpertNameText>{loginBackground.name}</ExpertNameText>
                        <ExpertStartDateText>Asurion expert since {loginBackground.since}</ExpertStartDateText>
                    </ExpertNameContainer>
                    <BackgroundImage src={loginBackground.file1x} srcSet={`${loginBackground.file2x} 2x`} />
                </RightContainer>
            </Container>
        </LightThemeProvider>
    );
};

export default SelectLogin;

function getGreetingTime() {
    const myDate = new Date();
    const hrs = myDate.getHours();

    if (hrs < 12) return 'Good morning';
    else if (hrs >= 12 && hrs <= 17) return 'Good afternoon';
    else if (hrs >= 17 && hrs <= 24) return 'Good evening';
}
