import {palette} from '@anywhere-expert/colors';

type LoginBackground = {
    name: string;
    since: number;
    file1x: string;
    file2x: string;
    color: string;
};

const loginBackgrounds: LoginBackground[] = [
    {
        name: 'Tyler Parrow',
        since: 2018,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Tyler-Parrow-2018.fb8296dc.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Tyler-Parrow-2018@2x.0798c13f.jpg',
        color: palette.brand.green,
    },
    {
        name: 'Angie Carter',
        since: 2018,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Angie-Carter-2018.213acde1.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Angie-Carter-2018@2x.441de049.jpg',
        color: palette.brand.green,
    },
    {
        name: 'Kyreshia Brown',
        since: 2018,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Kyreshia-Brown-2018.43a02bbe.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Kyreshia-Brown-2018@2x.568a8398.jpg',
        color: palette.primary.tennis.bright,
    },
    {
        name: 'Sean Sanders',
        since: 2019,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Sean-Sanders-2019.eaed81d3.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Sean-Sanders-2019@2x.d24e99eb.jpg',
        color: palette.brand.green,
    },
    {
        name: 'Raveen Grimes',
        since: 2017,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Raveen-Grimes-2017.65afc98a.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Raveen-Grimes-2017@2x.aed1f1b5.jpg',
        color: palette.primary.tennis.bright,
    },
    {
        name: 'Chandra Barkie',
        since: 2017,
        file1x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Chandra-Barkie-2017.8f97f9c6.jpg',
        file2x: 'https://anywhereexpert-static.mysoluto.com/media/LoginBG-Chandra-Barkie-2017@2x.fc3d4c68.jpg',
        color: palette.primary.tennis.bright,
    },
];

// Get a new image every X days
const NUM_OF_BACKGROUNDS = loginBackgrounds.length;
const CHANGE_EVERY_DAYS = 3;
const dayFromStart = Date.now() / 1000 / 60 / 60 / 24;
const backgroundIndex = Math.floor((dayFromStart % (NUM_OF_BACKGROUNDS * CHANGE_EVERY_DAYS)) / CHANGE_EVERY_DAYS);

export default loginBackgrounds[backgroundIndex];
