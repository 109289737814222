import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {UnauthorizedError} from '../services/authentication/UnauthorizedError';
import {
    tryLoginWithExistingTokens,
    signinRedirectCallback,
    removeStoredTokens,
    User,
    redirectToAuthProvider,
} from '@anywhere-expert/auth';
import SelectLogin from '../components/Login/SelectLogin';
import queryString from 'query-string';
import logger from '@anywhere-expert/logging';
import WelcomeStore from '../stores/WelcomeStore';
import {useLocation, useHistory} from 'react-router-dom';
import {usernamePasswordLoginCallback} from '@anywhere-expert/auth';
import {useAnalytics} from 'react-shisell';

type LoginStates = 'logging_in' | 'not_logged_in' | 'pending' | 'finished_login' | 'error';

const LoginPage = () => {
    const {dispatcher} = useAnalytics();
    const location = useLocation();
    const history = useHistory();
    const enhancedDispatcher = useMemo(() => dispatcher.createScoped('LogInPage').withIdentity('TechId', 'Unknown'), [
        dispatcher,
    ]);

    const [state, setState] = useState<LoginStates>('pending');
    const [error, setError] = useState<string | undefined>(undefined);
    const [redirectTo, setRedirectTo] = useState<string | undefined>(undefined);
    const [redirectQuery, setRedirectQuery] = useState<string | undefined>(undefined);

    const onLoggedIn = useCallback(async (userProfile: User, redirect?: string, redirectQuery?: string) => {
        try {
            WelcomeStore.setShow(true, userProfile.displayName);
            setState('logging_in');

            const PostLoginSteps = await import('../services/authentication/loginBehavior');
            await PostLoginSteps.postLoginSteps(userProfile);

            setRedirectTo(redirect);
            setRedirectQuery(redirectQuery);
            setState('finished_login');
        } catch (error) {
            logger.error('Post login actions failed', {err: error});
            if (error instanceof UnauthorizedError) {
                setError(error.message);
                setState('error');
            } else {
                setError(`Unexpected Error in Login process, We're working to fix it`);
                setState('error');
            }
            await removeStoredTokens();
        } finally {
            WelcomeStore.setShow(false);
        }
    }, []);

    const originSearch = useMemo(() => redirectQuery ?? location.state?.from?.search, [redirectQuery, location]);
    const pathname = useMemo(() => redirectTo ?? location.state?.from?.pathname ?? '/', [redirectTo, location]);
    const isAutoLogin = useCallback((search: string) => new URLSearchParams(search).get('autoLogin') === 'true', []);
    const getRedirectErrorMessage = useCallback((search: string) => {
        let error = new URLSearchParams(search).get('error');
        if (error?.includes('error_description:')) {
            error = error
                .substring(
                    error.indexOf('error_description:') + 'error_description:'.length,
                    error.indexOf(', error_uri')
                )
                .trim();
            if (error.startsWith("'") && error.endsWith("'")) {
                error = error.substring(1, error.length - 1);
            }
        }
        return error ?? undefined;
    }, []);

    const redirectToAsurionSSO = useCallback(
        () => redirectToAuthProvider('Asurion', location.state?.from?.pathname, location.state?.queryParams),
        [location]
    );

    useEffect(() => {
        enhancedDispatcher.withExtra('State', state).dispatch('View');
    }, [state, enhancedDispatcher]);

    useEffect(() => {
        async function run() {
            {
                if (window.location.pathname.endsWith('/auth')) {
                    try {
                        const loginData = await signinRedirectCallback();

                        if (loginData && loginData.tokens) {
                            logger.info('Got login data from redirect callback');
                            onLoggedIn(
                                loginData.userProfile,
                                loginData.redirectTo,
                                queryString.stringify(loginData.queryParams)
                            );
                            return;
                        }
                    } catch (err) {
                        logger.warn('Fail in signinRedirectCallback', {err});
                    }

                    // In case something want wrong in /auth route redirect to /login
                    history.push('/login');
                    return;
                }
                if (window.location.pathname === '/auth_username_password') {
                    const loginData = await usernamePasswordLoginCallback();
                    if (loginData && loginData.tokens) {
                        logger.info('Got login data from redirect callback');
                        onLoggedIn(loginData.userProfile);
                        return;
                    }
                    return;
                }
                if (window.location.pathname === '/error') {
                    const error = getRedirectErrorMessage(window.location.search);
                    setError(error);
                    setState('error');
                    return;
                }
                try {
                    const {userProfile, loggedIn} = await tryLoginWithExistingTokens();

                    if (loggedIn && userProfile) {
                        onLoggedIn(userProfile!);
                        return;
                    }
                } catch (err) {
                    logger.warn('error authenticating user with previous tokens', {err});
                }
                setState('not_logged_in');
            }
        }
        run();
    }, [onLoggedIn, history, getRedirectErrorMessage]);

    if (pathname.includes('/session-transcript/')) {
        if (state === 'finished_login') {
            return <Redirect to={{pathname, search: originSearch}} />;
        }

        if (state === 'not_logged_in') {
            return <SelectLogin ssoLoginRedirect={redirectToAsurionSSO} />;
        }

        return null;
    }

    switch (state) {
        case 'finished_login': {
            return <Redirect to={{pathname, search: originSearch}} />;
        }
        case 'error':
        case 'not_logged_in':
            return (
                <SelectLogin
                    ssoLoginRedirect={redirectToAsurionSSO}
                    shouldAutoLogin={isAutoLogin(originSearch)}
                    error={error}
                />
            );
        default: {
            return null;
        }
    }
};

export default LoginPage;
